




































































































































































































































































































































































.info-list-c:last-child{
  span{
    display: none;
  }
}
